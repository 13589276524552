import moment from "moment";
import 'moment-timezone';
import React, { useEffect, useContext, useState, useRef } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { informationAlert, confirmationAlert } from "../../molecules/alert/alert";
import { getInvoiceById, createInvoice, saveInvoiceRequest, updateInvoiceData } from '../../../services/facturaService';
import { validateInvoiceField, toBase64, formatValToCurrency, formatDate, getStatusName, validateInputDecimals } from '../../../utils/utils';
import { STATUS_ID, FILES_CATEGORIES } from '../../../utils/constans';
import { AppContextUser } from '../../../provider/userInSession';
import { FacturaContext } from '../../../provider/factura';
import { ClientContext } from '../../../provider/cliente';
import { ROLES } from '../../../config/roles';
import InvoiceCheck from '../../organism/invoice-check/invoiceCheck';
import Aside from '../../templates/aside/aside';
import Conceptos from '../../templates/conceptos/conceptos';
import GeneralInformation from '../../templates/informacion/generalInformation';
import Observaciones from '../../templates/observaciones/observaciones';
import Wrapper from '../../templates/wrapper/Wrapper';
import useLoader from '../../../utils/useLoader';
import './crearFactura.scss';
import { useTranslation } from "react-i18next";
import CrearFacturaConstants from "../../../constants/crear-factura-constants";

const useMountEffect = effect => useEffect(effect, []);

function CrearFactura(Props) {
  const constants = CrearFacturaConstants;
  const limitFiles = constants.NUMBER_FIVE;
  const SCROLLIDOBSERVATIONS = constants.NUMBER_FOUR;
  const [translate] = useTranslation(constants.GLOBAL);
  const TEXT = `${translate('invoices.createInvoice.invoiceClarification')}.`;
  const TEXT_STRONG = `${translate('invoices.createInvoice.brandClarification')}.`;
  const { idFacturas } = useParams();
  const history = useHistory();

  const { dataClient, setDataClient } = useContext(ClientContext);
  const { dataUser } = useContext(AppContextUser);
  const [dataFactura, setDataFactura] = useContext(FacturaContext);
  const [loader, showLoader, hideLoader] = useLoader();
  const [buttons, setButtons] = useState([]);
  const [buttonState, setButtonState] = useState(constants.FALSE);
  const [showHideComponents, setShowHideComponents] = useState(constants.FALSE);
  const [fieldTitle, setFieldTitle] = useState(constants.CHANGE_STATE);
  const [isDuplicate, setIsDuplicate] = useState(constants.FALSE);
  const observationRef = useRef(constants.NULL);
  const executeScroll = () => observationRef.current.scrollIntoView();
  const [brandControl, setBrandControl] = useState(constants.TRUE);
  const changeClientState = useState(constants.FALSE);
  let inicialConsecutivo;
  let date = new Date();
  const stringDate = JSON.stringify(date);
  let currentYear = stringDate.substring(constants.NUMBER_ONE, constants.NUMBER_FIVE);
  let currentMonth = stringDate.substring(constants.NUMBER_SIX, constants.NUMBER_EIGHT);
  let currentDay = stringDate.substring(constants.NUMBER_NINE, constants.NUMBER_ELEVEN);
  let fullDeadlineDateMin = currentYear + constants.MIDDLE_DASH + currentMonth + constants.MIDDLE_DASH + currentDay;
  var lastDay = new Date(date.getFullYear(), date.getMonth() + constants.NUMBER_ONE, constants.NUMBER_ZERO);
  const stringLastDay = JSON.stringify(lastDay)
  let numberOfLastDay = stringLastDay.substring(constants.NUMBER_NINE, constants.NUMBER_ELEVEN)
  let fullDeadlineDateMax = currentYear + constants.MIDDLE_DASH + currentMonth + constants.MIDDLE_DASH + numberOfLastDay;

  const setConsecutive = (sedeId) => {
    switch (sedeId) {
      case constants.NUMBER_TWO:
        inicialConsecutivo = constants.CONSECUTIVO.SEDE_BE;
        break;
      case constants.NUMBER_THREE:
        inicialConsecutivo = constants.CONSECUTIVO.SEDE_CE;
        break;
      case constants.NULL:
        inicialConsecutivo = constants.CONSECUTIVO.SEDE_NN;
        break;
      default:
        inicialConsecutivo = constants.CONSECUTIVO.SEDE_ME;
        break;
    }
  }

  const updateDate = event => {
    if (event.type) {
      dataFactura.fechaLimiteEntrega = event.target.value;
      setDataFactura({
        type: constants.TYPE_ADD,
        payload: { fechaLimiteEntrega: event.target.value }
      });
    } else {
      switch (event.tag) {
        case constants.REQUEST_DATE:
          dataFactura.fechaSolicitud = event.value;
          break;
        case constants.LIMIT_DATE:
          dataFactura.fechaLimiteEntrega = event.value;
          break;
        default:
          informationAlert(constants.ALERT_ERROR, translate('alerts.oops'), translate('alerts.dateError'));
          break;
      }
      setDataFactura({
        type: constants.TYPE_ADD,
        payload: { ...dataFactura }
      });
    }

    setInvoiceInformation([
      {
        tag: `${translate('invoices.createInvoice.applicationDate')}`,
        value: formatDate(dataFactura.fechaSolicitud, constants.FORMAT_DATE),
        type: constants.TYPE_ORANGE
      },
      {
        tag: `${translate('invoices.createInvoice.deliveryDeadline')}`,
        value: formatDate(dataFactura.fechaLimiteEntrega, constants.DATE),
        type: constants.DATE_LOWER,
        callback: updateDate,
        min: fullDeadlineDateMin,
        max: fullDeadlineDateMax
      }
    ])
  }

  const checkUpdateDates = (dateToFormat, dateType) => {
    let splitDate = dateToFormat.split(constants.MIDDLE_DASH);
    let facDay = splitDate[constants.NUMBER_TWO].substring(constants.NUMBER_ZERO, constants.NUMBER_TWO);
    let facMonth = splitDate[constants.NUMBER_ONE];
    let facYear = splitDate[constants.NUMBER_ZERO];
    if (facYear < currentYear || facMonth < currentMonth || facDay < currentDay) {
      updateDate({ tag: dateType, value: fullDeadlineDateMin })
      return fullDeadlineDateMin;
    } else {
      return facYear + constants.MIDDLE_DASH + facMonth + constants.MIDDLE_DASH + facDay;
    }
  }

  const INITIAL_INFORMATION = [
    {
      tag: `${translate('invoices.createInvoice.applicationDate')}: `,
      value: formatDate(dataFactura.fechaSolicitud, constants.FORMAT_DATE),
      type: constants.TYPE_ORANGE
    },
    {
      tag: `${translate('invoices.createInvoice.deliveryDeadline')}: `,
      value: formatDate(dataFactura.fechaLimiteEntrega, constants.DATE),
      type: constants.DATE_LOWER, callback: updateDate,
      min: fullDeadlineDateMin,
      max: fullDeadlineDateMax
    }
  ];
  const [invoiceInformation, setInvoiceInformation] = useState(INITIAL_INFORMATION);
  React.useEffect(() => {
    const _invoiceInformation = [...invoiceInformation];
    if (_invoiceInformation[constants.NUMBER_TWO]) {
      _invoiceInformation[constants.NUMBER_ZERO].tag = `${translate('invoices.createInvoice.requestedBy')}:`;
      _invoiceInformation[constants.NUMBER_ONE].tag = `${translate('invoices.createInvoice.state')}:`;
      _invoiceInformation[constants.NUMBER_TWO].tag = `${translate('invoices.createInvoice.noRequest')}:`;
      _invoiceInformation[constants.NUMBER_THREE].tag = `${translate('invoices.createInvoice.applicationDate')}: `;
      _invoiceInformation[constants.NUMBER_FOUR].tag = `${translate('invoices.createInvoice.deliveryDeadline')}: `;
    } else {
      _invoiceInformation[constants.NUMBER_ZERO].tag = `${translate('invoices.createInvoice.applicationDate')}`;
      _invoiceInformation[constants.NUMBER_ONE].tag = `${translate('invoices.createInvoice.deliveryDeadline')}`;
    }
    setInvoiceInformation(_invoiceInformation);
    setButtons(buttons.map(button => ({...button, name: translate(button.t)})));
  }, [translate]);

  const resetApprovingFieldsOnInvoice = () => {
    dataFactura.files = dataFactura.files.filter(
      (item) => item?.categoria !== FILES_CATEGORIES.INVOICE
    );
    dataFactura.trm = constants.NUMBER_ZERO;
    dataFactura.consecutivo = constants.NULL;
    setDataFactura({
      type: constants.TYPE_ADD,
      payload: {
        ...dataFactura,
      }
    });
  }

  const actualizarDataFactura = event => {
    setDataFactura({
      type: constants.TYPE_ADD,
      payload: { ...dataFactura, [event.target.name]: event.target.value }
    });
  }

  const showInvoiceCheckComponent = (event) => { setShowHideComponents(constants.TRUE); }

  const updateInvoiceTrm = (trm) => {
    dataFactura.trm = validateInputDecimals(trm);
    setDataFactura({
      type: constants.TYPE_ADD,
      payload: { ...dataFactura }
    });
  }

  const actualizarObservaciones = (event) => {
    dataFactura.observacionesEstado = event.target.value;
    setDataFactura({
      type: constants.TYPE_ADD,
      payload: { ...dataFactura }
    })
  };

  const showHideComponent = (component) => {
    switch (component) {
      case constants.BUTTON:
        if (!idFacturas) return constants.TRUE;
        if (dataFactura.estadoId === STATUS_ID.GUARDADA) return constants.TRUE;
        if (!idFacturas || isDuplicate) return constants.TRUE;
        return constants.FALSE;
      case constants.INVOICE_CHECK:
        if (!idFacturas || isDuplicate) return constants.FALSE;
        if ((dataUser.rol === ROLES.SOLICITANTE || dataUser.rol === ROLES.SUPERVISOR) && (dataFactura.estadoId === STATUS_ID.APROBADA || dataFactura.estadoId === STATUS_ID.ANULADA)) return constants.TRUE;
        if ((dataUser.rol === ROLES.SOLICITANTE || dataUser.rol === ROLES.SUPERVISOR) && dataFactura.estadoId !== STATUS_ID.DEVUELTA) return constants.FALSE;
        if (dataFactura.estadoId === STATUS_ID.GUARDADA) return constants.FALSE;
        return constants.TRUE;
      default:
        informationAlert(constants.ALERT_ERROR, translate('alerts.oops'), component);
    }
  }

  const setFileOnProvider = (files, key, fileCategory) => {
    let filesFiltered = dataFactura.files.filter(file => file.categoria !== fileCategory);
    if (!(dataFactura.estadoId === STATUS_ID.ANULADA || dataFactura.estadoId === STATUS_ID.APROBADA)) {
      if (files.length) {
        files.map(file => {
          if (!file.archivoId)
            toBase64(file, fileCategory, (otherFile) => {
              dataFactura.files.push({
                categoria: fileCategory,
                nombreArchivo: file.name,
                file: otherFile.file
              })
              setDataFactura({
                type: constants.TYPE_ADD,
                payload: { ...dataFactura }
              });
            })
        });
      } else {
        dataFactura.files = filesFiltered.concat(files);
        setDataFactura({
          type: constants.TYPE_ADD,
          payload: { ...dataFactura }
        });
      }
    }
  }

  const reviewInvoice = async () => {
    const updateFactura = await {
      ...dataFactura,
      estadoId: STATUS_ID.REVISADA
    };
    resetApprovingFieldsOnInvoice();
    callUpdateInvoice(updateFactura);
  };

  const returnInvoice = async () => {
    if (!dataFactura.observacionesEstado) {
      informationAlert(constants.ALERT_ERROR, translate('alerts.alert'), `${translate('alerts.invoiceReturn')}.`);
    } else {
      resetApprovingFieldsOnInvoice();
      const updateFactura = await {
        ...dataFactura,
        estadoId: STATUS_ID.DEVUELTA,
      };
      callUpdateInvoice(updateFactura);
    }
  };

  const approveInvoice = async () => {
    if (!dataFactura.consecutivo) {
      informationAlert(constants.ALERT_ERROR, translate('alerts.alert'), translate('alerts.consecutiveInvoice'));
    } else if (dataFactura.files.length === constants.NUMBER_ZERO) {
      informationAlert(constants.ALERT_ERROR, translate('alerts.alert'), translate('alerts.billSale'));
    } else {
      const updateFactura = await {
        ...dataFactura,
        estadoId: STATUS_ID.APROBADA,
        fechaFacturacion: moment().tz(constants.TIME_ZONE).format(constants.FORMAT_DATE_YYYY_MM_DD),
      };
      callUpdateInvoice(updateFactura);
    }
  };

  const annulInvoice = async () => {
    if (!dataFactura.observacionesEstado) {
      informationAlert(constants.ALERT_ERROR, translate('alerts.alert'), `${translate('alerts.invoiceAnnulment')}.`);
    } else {
      confirmationAlert(
        constants.ALERT_WARNING,
        translate('alerts.confirmInvoiceAnnulment'),
        constants.EMPTY,
        translate('transversal.yes'),
        translate('transversal.no'),
        async () => {
          const updateFactura = await {
            ...dataFactura,
            estadoId: STATUS_ID.ANULADA,
          };
          callUpdateInvoice(updateFactura);
        }
      );
    }
  };

  const returnToReviwedState = () => {
    const updateFactura = {
      ...dataFactura,
      estadoId: STATUS_ID.REVISADA,
    };

    callUpdateInvoice(updateFactura);
  };

  const updateInvoice = async () => {
    const updateFactura = await {
      ...dataFactura,
      estadoId: STATUS_ID.PENDIENTE,
      observacionesEstado: constants.EMPTY,
    };
    try {
      validateInvoiceField(updateFactura, () => {
        callUpdateInvoice(updateFactura);
      });
    } catch (error) {
      informationAlert(error.type, error.msg, error.details);
    }
  };

  const cancelInvoice = async () => {
    if (dataFactura.estadoId === STATUS_ID.DEVUELTA) {
      confirmationAlert(
        constants.ALERT_WARNING,
        translate('alerts.confirmInvoiceCancellation'),
        constants.EMPTY,
        translate('transversal.yes'),
        translate('transversal.no'),
        async () => {
          const updateFactura = await {
            ...dataFactura,
            estadoId: STATUS_ID.CANCELADA,
          };
          callUpdateInvoice(updateFactura);
        }
      );
    }
  }

  const callUpdateInvoice = (invoiceData) => {
    setButtonState(constants.TRUE);
    showLoader();
    
    invoiceData.facSolicitudId = idFacturas

    updateInvoiceData(invoiceData)
      .then((res) => {
        hideLoader();
        if (res.data.status == constants.STATUS_200) {
          informationAlert(constants.ALERT_SUCCES, translate('alerts.success'), translate('alerts.invoiceUpdatedCorrectly'));
          history.push(constants.ROOT_PENDING_INVOICE);
        } else {
          setButtonState(constants.FALSE);
          informationAlert(constants.ALERT_ERROR, translate('alerts.oops'), res.data.userMessage);
        }
      }).catch(err => {
        setButtonState(constants.FALSE);
        hideLoader();
        informationAlert(constants.ALERT_ERROR, translate('alerts.alert'), err);
      })
  }

  const requestInvoice = async () => {
    if (dataUser.rol === constants.ROLE.SUPERVISOR) {
      dataFactura.usuarioId = dataUser.userId;
      dataFactura.usuarioEmail = dataUser.userName.replace(constants.SPACE, constants.POINT);
    }
    let invoiceToCreate = await {
      ...dataFactura,
      estadoId: STATUS_ID.PENDIENTE
    };
    try {
      validateInvoiceField(dataFactura, () => {
        showLoader();
        setButtonState(constants.TRUE);
        createInvoice(invoiceToCreate)
          .then(response => {
            hideLoader();
            switch (response.data.status) {
              case constants.STATUS_200:
                history.push(constants.ROOT_INVOICE);
                informationAlert(constants.ALERT_SUCCES, translate('alerts.success'), response.data.userMessage)
                break;
              default:
                informationAlert(constants.ALERT_ERROR, translate('alerts.oops'), response.data.userMessage);
                setButtonState(constants.FALSE);
                break;
            }
          })
          .catch(err => {
            hideLoader();
            setButtonState(constants.FALSE);
            informationAlert(constants.ALERT_ERROR, translate('alerts.oops'), err.message);
          });
      });
    } catch (error) {
      informationAlert(error.type, error.msg, error.details);
    }
  }

  const saveInvoice = async () => {
    let mensaje;
    if (dataFactura.clienteId === constants.NUMBER_MINUS_ONE) {
      mensaje = `${translate('alerts.customerRequired')}.`
    }
    if (dataFactura.filialId === constants.NUMBER_ZERO) {
      mensaje = `${translate('alerts.subsidiaryRequired')}.`
    }
    if (dataFactura.plazoPago === constants.SPACE) {
      mensaje = `${translate('alerts.paymentTermRequired')}.`
    }

    if (mensaje) {
      informationAlert(constants.ALERT_ERROR, translate('alerts.oops'), mensaje);
    } else {
      showLoader();
      setButtonState(constants.TRUE);
      let invoiceToSave = await {
        ...dataFactura,
      };

      if (dataFactura.estadoId === STATUS_ID.PENDIENTE) {
        invoiceToSave = {
          ...dataFactura,
          estadoId: STATUS_ID.GUARDADA
        }
      }
      saveInvoiceRequest(invoiceToSave).then(response => {
        hideLoader();
        switch (response.data.status) {
          case constants.STATUS_200:
            history.push(constants.ROOT_INVOICE);
            informationAlert(constants.ALERT_SUCCES, translate('alerts.invoiceSaved'), response.data.userMessage);
            break;
          default:
            informationAlert(constants.ALERT_ERROR, translate('alerts.oops'), response.data.userMessage);
            setButtonState(constants.FALSE);
            break;
        }
      }).catch(error => {
        hideLoader();
        setButtonState(constants.FALSE);
        informationAlert(constants.ALERT_ERROR, translate('alerts.oops'), error.message);
      });
    }
  }

  const INITIAL_BUTTONS = [
    { t: 'transversal.save', name: translate('transversal.save'), primary: constants.WHITE, onClick: saveInvoice, disabled: buttonState },
    { t: 'invoices.createInvoice.requestInvoice', name: translate('invoices.createInvoice.requestInvoice'), primary: constants.DARK, onClick: requestInvoice, disabled: buttonState }
  ];

  const getData = async () => {
    if (idFacturas) {
      const data = { facSolicitudId: idFacturas };
      showLoader();
      await getInvoiceById(data).then(res => {
        hideLoader();
        if (res.data.status === constants.STATUS_200) {
          setDataFactura({
            type: constants.TYPE_ADD,
            payload: res.data.data.factura
          });
          const invoiceDataToShow = [{ tag: `${translate('invoices.createInvoice.requestedBy')}:`, value: res.data.data.factura.usuarioEmail },
            { tag: `${translate('invoices.createInvoice.state')}:`, value: getStatusName(res.data.data.factura.estadoId) },
            { tag: `${translate('invoices.createInvoice.noRequest')}:`, value: res.data.data.factura.facSolicitudId },
            { tag: `${translate('invoices.createInvoice.applicationDate')}:`, value: formatDate(res.data.data.factura.fechaSolicitud, constants.FORMAT_DATE) },
            { tag: `${translate('invoices.createInvoice.deliveryDeadline')}:`, value: formatDate(res.data.data.factura.fechaLimiteEntrega, constants.FORMAT_DATE), callback: updateDate, type: constants.DATE_LOWER },
          ];

          setInvoiceInformation(invoiceDataToShow);
          setDataClient({
            type: constants.TYPE_ADD,
            payload: res.data.data.cliente
          });
          setConsecutive(dataClient.sedeId);
        } else {
          informationAlert(constants.ALERT_ERROR, translate('alerts.alert'), res.data.userMessage);
          res.data.status === constants.STATUS_403 ? history.push(constants.ROOT) : history.push(constants.ROOT_INVOICE);
        }
      }).catch(error => {
        hideLoader();
        informationAlert(error, error.msg, error.details)
      });
    } else {
      setDataFactura({ type: constants.TYPE_RESET });
      setDataClient({
        type: constants.TYPE_RESET,
      });
    }
  };

  if (!dataFactura.facSolicitudId) {
    dataClient.usuarioId = dataUser.userId;
    dataFactura.usuarioId = dataUser.userId;
  }

  const handleDuplicate = () => {
    window.history.replaceState(constants.NULL, constants.CREATE_INVOICE, constants.ROOT);
    delete dataFactura.facSolicitudId;
    delete dataFactura.fechaFacturacion;
    delete dataFactura.consecutivo;
    delete dataFactura.trm
    dataFactura.files = [];
    dataFactura.estadoId = constants.NUMBER_ONE;
    dataFactura.fechaLimiteEntrega = checkUpdateDates(dataFactura.fechaLimiteEntrega, constants.LIMIT_DATE);
    dataFactura.fechaSolicitud = checkUpdateDates(dataFactura.fechaSolicitud, constants.REQUEST_DATE);
    setDataFactura({
      type: constants.TYPE_ADD,
      payload: { ...dataFactura }
    });
    setIsDuplicate(constants.TRUE);
    setInvoiceInformation([
      { tag: `${translate('invoices.createInvoice.applicationDate')}: `, value: formatDate(dataFactura.fechaSolicitud, constants.FORMAT_DATE), type: constants.TYPE_ORANGE },
      { tag: `${translate('invoices.createInvoice.deliveryDeadline')}: `, value: formatDate(dataFactura.fechaLimiteEntrega, constants.DATE), type: constants.DATE_LOWER, callback: updateDate, min: fullDeadlineDateMin, max: fullDeadlineDateMax }
    ]);
    setButtons(INITIAL_BUTTONS);
  }

  function focusObservations() {
    executeScroll();
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (dataFactura.estadoId === STATUS_ID.APROBADA || dataFactura.estadoId === STATUS_ID.ANULADA) {
      setShowHideComponents(constants.TRUE);
    }
    if (dataFactura.facSolicitudId) {
      switch (dataFactura.estadoId) {
        case STATUS_ID.ANULADA:
          setFieldTitle(translate('invoices.createInvoice.annulatedInvoice'));
          setShowHideComponents(constants.TRUE);
          setButtons([]);
          break;
        case STATUS_ID.APROBADA:
          if (dataUser.rol === ROLES.SOLICITANTE || dataUser.rol === ROLES.SUPERVISOR) {
            setFieldTitle(translate('invoices.createInvoice.approvedInvoice'));
            setShowHideComponents(constants.TRUE);
            setButtons([]);
            break;
          } else {
            setFieldTitle(translate('invoices.createInvoice.approvedInvoice'));
            setShowHideComponents(constants.TRUE);
            setButtons([
              { t:'transversal.annular', name: translate('transversal.annular'), primary: constants.DARK, onClick: annulInvoice, disabled: buttonState },
              { t:'invoices.createInvoice.returnReviewed', name: translate('invoices.createInvoice.returnReviewed'), primary: constants.WHITE, onClick: returnToReviwedState, disabled: buttonState }
            ]);
            break;
          }
        case STATUS_ID.CANCELADA:
          setFieldTitle(translate('invoices.createInvoice.canceledInvoice'));
          setButtons([]);
          break;
        case STATUS_ID.DEVUELTA:
          setFieldTitle(translate('invoices.createInvoice.changeStatus'));
          setButtons([
            { t: 'transversal.cancel', name: translate('transversal.cancel'), primary: constants.WHITE, onClick: cancelInvoice, disabled: buttonState },
            { t: 'transversal.save', name: translate('transversal.save'), primary: constants.WHITE, onClick: saveInvoice, disabled: buttonState },
            { t: 'transversal.send', name: translate('transversal.send'), primary: constants.DARK, onClick: updateInvoice, disabled: buttonState }
          ]);
          break;
        case STATUS_ID.GUARDADA:
          setButtons(INITIAL_BUTTONS);
          break;
        default:
          setFieldTitle(translate('invoices.createInvoice.changeStatus'));
          setButtons([
            { t: 'transversal.review', name: translate('transversal.review'), primary: constants.OUTLINED, variant: constants.SECONDARY, onClick: reviewInvoice, disabled: buttonState },
            { t: 'transversal.return', name: translate('transversal.return'), primary: constants.WHITE, variant: constants.PRIMARY, onClick: returnInvoice, disabled: buttonState },
            { t: 'transversal.approve', name: translate('transversal.approve'), primary: constants.DARK, variant: constants.PRIMARY, onClick: showHideComponents ? approveInvoice : showInvoiceCheckComponent, disabled: buttonState }
          ]);
          break;
      }
    } else {
      setButtons(INITIAL_BUTTONS);
    }
    if (dataFactura.estadoId === SCROLLIDOBSERVATIONS) {
      focusObservations();
    }
  }, [dataFactura]);

  useEffect(() => {
    if (dataFactura.filialId && dataClient.clienteId) {
      setBrandControl(constants.FALSE);
    }

    else setBrandControl(constants.TRUE);
  }, [dataClient.clienteId, dataFactura.filialId])

  const [blur, setBlur] = useState(constants.FALSE);

  return (
    <Wrapper aside={<Aside blur={blur} setBlur={setBlur} />} blur={blur}>
      <form>
        <GeneralInformation
          limitFiles={limitFiles}
          context={FacturaContext}
          isDuplicate={isDuplicate}
          handleDuplicate={handleDuplicate}
          dataToShow={invoiceInformation}
          changeClient={changeClientState}
          show={true}
        />

        <Conceptos 
        isDuplicate={isDuplicate} 
        brandDisable={brandControl} 
        changeClient={changeClientState} 
        />

        <Observaciones
          title={translate('transversal.observations')}
          text={TEXT}
          context={FacturaContext}
          textStrong={TEXT_STRONG}
          buttonText={constants.REQUEST_INVOICE}
          showButton={showHideComponent(constants.BUTTON)}
          buttons={buttons}
        />

        {showHideComponent(constants.INVOICE_CHECK) &&
          <InvoiceCheck
            limitFiles={constants.NUMBER_ONE}
            buttons={buttons}
            headerTitle={fieldTitle}
            showComponent={showHideComponents}
            showTextArea={dataFactura.estadoId === STATUS_ID.DEVUELTA || dataFactura.estadoId === STATUS_ID.CANCELADA || dataFactura.estadoId === STATUS_ID.ANULADA}
            handleFileChange={setFileOnProvider} handleDataChange={actualizarDataFactura}
            disabled={(dataFactura.estadoId === STATUS_ID.ANULADA || dataFactura.estadoId === STATUS_ID.APROBADA)}
            handleUptadeTRM={updateInvoiceTrm} handleObservacionesChange={actualizarObservaciones}
            files={dataFactura.files.filter(file => file.categoria === FILES_CATEGORIES.INVOICE)}
            consecutivo={dataFactura.consecutivo} observacionesEstado={dataFactura.observacionesEstado}
            trm={formatValToCurrency(constants.NUMBER_ONE, dataFactura.trm ? dataFactura?.trm : constants.NUMBER_ZERO)}
          />
        }

        {loader}
      </form>
      <div ref={observationRef}></div>
    </Wrapper>
  );
}

export default CrearFactura;
